import React from 'react';
import Block from '../../Widgets/Block';
import useInvoiceData from '../../Hooks/useInvoiceData';
import {getInvoiceCategory} from '../../Utils/InvoiceUtil';
import {TAX_TYPE, CARRIER_TYPE} from '../../dictionary';
import * as Ant from 'antd';

export default function InvoiceConfigBlock(props) {
  const {invoice} = props;
  const {detail, message, items} = useInvoiceData({invoice});

  return (
    <Block>
      <h2>詳細資訊</h2>
      <div className="divider" />
      <div className="row" style={{alignItems: 'flex-start', margin: 0}}>
        <div style={{flexBasis: 400}}>
          <div className="row">
            <h4>發票類型</h4>
            <div> {getInvoiceCategory(invoice)} </div>
          </div>
          {invoice.love_code ? (
            <div className="row">
              <h4>愛心碼</h4>
              <div>{invoice.love_code || '-'}</div>
            </div>
          ) : invoice.category === 'B2B' ? (
            <div className="row">
              <h4>統一編號</h4>
              <div>{invoice.buyer_ubn || '-'}</div>
            </div>
          ) : (
            <React.Fragment>
              <div className="row">
                <h4>載具類型</h4>
                <div style={{color: '#999'}}>
                  {CARRIER_TYPE[invoice.carrier_type]}
                </div>
              </div>
              <div className="row">
                <h4>載具號碼</h4>
                <div>{invoice.carrier_num || '-'}</div>
              </div>
            </React.Fragment>
          )}
          <div className="row">
            <h4>稅別</h4>
            <div>{TAX_TYPE[invoice.tax_type]}</div>
          </div>
          <div className="row">
            <h4>稅率</h4>
            <div>{invoice.tax_rate}%</div>
          </div>
        </div>

        {Array.isArray(items) ? (
          <Ant.Table
            columns={[
              {key: 'name', dataIndex: 'name', title: '品項名稱'},
              {key: 'price', dataIndex: 'price', title: '單價'},
              {key: 'quantity', dataIndex: 'quantity', title: '數量'},
              {key: 'amount', dataIndex: 'amount', title: '小計'},
            ]}
            dataSource={items}
            rowKey={'title'}
            pagination={false}
            style={{flex: 1}}
          />
        ) : (
          <div style={{flex: 1}}>解析錯誤</div>
        )}
      </div>
    </Block>
  );
}
