import React from 'react';
import Block from '../../Widgets/Block';
import useInvoiceData from '../../Hooks/useInvoiceData';
import {getInvoiceCategory} from '../../Utils/InvoiceUtil';
import {INVOICE_STATUS, INVOICE_AFTER_TREATMENT_STATE} from '../../dictionary';
import {UISTATE as DialogUiState} from '../../Dialogs/InvoiceEditDialog';
import Alert from '../../Widgets/Alert';
import IconButton from '../../Widgets/IconButton';
import {Edit} from '@styled-icons/boxicons-solid/Edit';
import Anchor from '../../Widgets/Anchor';
import {useOutlet} from 'reconnect.js';
const appConfig = require('../../data.json');

export default function InvoiceBlock(props) {
  const {invoice, setOpenDialog} = props;
  const {detail, message, items} = useInvoiceData({invoice});
  const [actions] = useOutlet('actions');
  return (
    <Block>
      <h2>發票資訊</h2>
      <div className="divider" />
      <div>
        {invoice.voided && (
          <div className="row">
            <Alert style={{flex: 1}} color={appConfig.colors.error}>
              此發票已作廢！
              {invoice.void_reason ? `原因:${invoice.void_reason}` : ''}
            </Alert>
          </div>
        )}
        <div className="row">
          <h4>發票號碼</h4>
          <div>{invoice.invoice_number || '-'}</div>
        </div>
        <div className="row">
          <h4>開立時間</h4>
          <div>
            {`${invoice.created}`.split('T')[0]}{' '}
            {`${invoice.created}`.slice(11, 16)}
          </div>
        </div>
        <div className="row">
          <h4>開立方式</h4>
          <div> {getInvoiceCategory(invoice)} </div>
        </div>
        <div className="row">
          <h4>發票狀態</h4>
          <div>{INVOICE_STATUS[invoice.invoice_status]}</div>
        </div>
        {invoice.invoice_status === 'failure' ? (
          <div className="row">
            <h4>失敗原因</h4>
            <div>{message}</div>
          </div>
        ) : null}

        <div className="row">
          <h4>後續處理狀態</h4>
          <div>{INVOICE_AFTER_TREATMENT_STATE[invoice.after_threatment]}</div>

          <IconButton
            onClick={() => setOpenDialog(DialogUiState.after_treatment)}
            disabled={invoice.invoice_status === 'success'}
            hoverColor={appConfig.colors.sub}>
            <Edit />
          </IconButton>
        </div>

        {detail && (
          <React.Fragment>
            <div className="row">
              <h4>銷售額</h4>
              <div>{detail.request_data.Amt} 元</div>
            </div>
            <div className="row">
              <h4>稅額</h4>
              <div>{detail.request_data.TaxAmt} 元</div>
            </div>
          </React.Fragment>
        )}
        <div className="row">
          <h4>總金額</h4>
          <div>{invoice.amount} 元</div>
        </div>
        {/* <div className="row">
          <div style={{ color: "#999" }}>
            單價 {invoice.amount} 元 * 數量 {invoice.item_quantity}+ 稅率{" "}
            {invoice.tax_rate} % = 總額 {invoice.total_amount} 元
          </div>
        </div> */}
        {invoice.order && (
          <div className="row">
            <h4>訂單編號</h4>
            <Anchor to={`/order/?id=${invoice.order}`}>
              {invoice.display_id || '---'}
            </Anchor>
          </div>
        )}
        {invoice.monthly_order && (
          <div className="row">
            <h4>月結對帳單編號</h4>
            <Anchor to={`/monthly/?id=${invoice.monthly_order}`}>
              {invoice.monthly_order}
            </Anchor>
          </div>
        )}
      </div>
    </Block>
  );
}
