import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import BackButton from '../../Components/BackButton';
import Button from '../../Widgets/Button';
import Row from '../../Widgets/Row';
import Block from '../../Widgets/Block';
import Anchor from '../../Widgets/Anchor';
import InvoiceBlock from './InvoiceBlock';
import InvoiceConfigBlock from './InvoiceConfigBlock';
import InvoiceEditDialog, {
  UISTATE as DialogUiState,
} from '../../Dialogs/InvoiceEditDialog';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
const appConfig = require('../../data.json');

const qs = require('query-string');

export default function InvoiceDetail(props) {
  const {id} = qs.parse(props.location.search);
  const [order, setOrder] = useState(null);
  const [productSpec, setProductSpec] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [extraLoading, setExtraLoading] = useState(false);
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [profile] = useOutlet('user');

  const getRecord = useCallback(async () => {
    setLoading(true);
    try {
      let resp = await actions.getInvoice(id);
      setInvoice(resp);
    } catch (ex) {
      console.warn(ex);
      Ant.message.warning('取得發票錯誤');
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getRecord();
  }, []);

  // useEffect(() => {
  //   async function getOrder() {
  //     try {
  //       return await actions.getOrder(invoice.order);
  //     } catch (err) {
  //       Ant.message.warning('取得訂單錯誤');
  //     }
  //   }

  //   async function getOrderItems(order) {
  //     try {
  //       return await actions.getOrderItems(order.id);
  //     } catch (err) {
  //       Ant.message.warning('取得稿件資訊錯誤');
  //     }
  //   }
  //   async function getSpec(order) {
  //     try {
  //       return await fetchProductSpecByOrder(order);
  //     } catch (err) {
  //       Ant.message.warning('取得商品規格錯誤');
  //     }
  //   }
  //   (async () => {
  //     if (invoice) {
  //       setExtraLoading(true);
  //       const order = await getOrder();
  //       setOrder(order);
  //       if (order) {
  //         const [orderItems, spec] = await Promise.all([
  //           getOrderItems(order),
  //           getSpec(order),
  //         ]);
  //         setOrderItems(orderItems);
  //         setProductSpec(spec);
  //       }
  //       setExtraLoading(false);
  //     }
  //   })();
  // }, [invoice]);

  if (loading || extraLoading) {
    return (
      <Wrapper>
        <BackButton />
        <h2>載入中...</h2>
      </Wrapper>
    );
  }

  if (!invoice) {
    return (
      <Wrapper>
        <BackButton />
        <h2>找不到發票</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton />
        <div style={{flex: 1}} />
        <Button
          type="primary"
          disabled={invoice.voided}
          onClick={() => setOpenDialog(DialogUiState.void)}>
          作廢發票
        </Button>
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={getRecord}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>

      <Ant.Row gutter={[20, 20]}>
        <Ant.Col span={12}>
          {/* 發票資訊 */}
          <InvoiceBlock invoice={invoice} setOpenDialog={setOpenDialog} />
        </Ant.Col>

        <Ant.Col span={12}>
          {/* 買受人資訊 */}
          <Block>
            <h2>買受人資訊</h2>
            <div className="divider" />
            <div>
              {(invoice.order || invoice.monthly_order) && (
                <div className="row">
                  <h4>會員編號</h4>
                  <Anchor to={`/member/?id=${invoice.buyer}`}>
                    # {invoice.buyer}
                  </Anchor>
                </div>
              )}

              <div className="row">
                <h4>買受人名稱</h4>
                <div>{invoice.buyer_name}</div>
              </div>
              <div className="row">
                <h4>買受人電子信箱</h4>
                <div>{invoice.buyer_email}</div>
              </div>

              <div className="row">
                <h4>買受人統編</h4>
                <div>{invoice.buyer_ubn || '-'}</div>
              </div>
              {/* 
          <div className="row">
            <h4>行動電話</h4>
            <div>{invoice.buyer_phone}</div>
          </div>
          <div className="row">
            <h4>買受人地址</h4>
            <div>{invoice.buyer_address}</div>
          </div> */}
            </div>
          </Block>
        </Ant.Col>
        <Ant.Col span={24}>
          <InvoiceConfigBlock invoice={invoice} />
        </Ant.Col>
      </Ant.Row>

      <InvoiceEditDialog
        uiState={openDialog}
        onClose={() => setOpenDialog(false)}
        onUpdate={getRecord}
        invoice={invoice}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
