import React from 'react';

export default function useInvoiceData({invoice}) {
  const detail = React.useMemo(() => {
    try {
      return JSON.parse(invoice.invoice_detail);
    } catch (err) {}
  }, [invoice]);

  const items = React.useMemo(() => {
    if (detail?.request_data) {
      if (detail.request_data.items) {
        return detail.request_data.items;
      } else {
        try {
          const {
            ItemName,
            ItemUnit,
            ItemPrice,
            ItemAmt,
            ItemCount,
          } = detail.request_data;
          const names = ItemName.split('|');
          const units = ItemUnit.split('|');
          const prices = ItemPrice.split('|');
          const amounts = ItemAmt.split('|');
          const quantities = ItemCount.split('|');

          return names.map((name, idx) => ({
            name: name,
            unit: units[idx],
            price: prices[idx], //單價
            quantity: quantities[idx],
            amount: amounts[idx], //小計
          }));
        } catch (err) {}
      }
    }
  }, [detail]);

  return {
    detail,
    message: detail?.Message,
    items,
  };
}
