import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Close} from '@styled-icons/material/Close';

import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {errorHandler, ErrInvoice} from '../errors';
import Row from '../Widgets/Row';
import Button from '../Widgets/Button';
import Block from '../Widgets/Block';
import Hint from '../Widgets/Hint';
import {INVOICE_AFTER_TREATMENT_STATE} from '../dictionary';

export const UISTATE = {
  void: '作廢發票',
  after_treatment: '發票後續處理',
};

export default function InvoiceEditDialog(props) {
  const {invoice, onUpdate, onClose, uiState} = props;
  const [values, setValues] = useState({
    reason: '',
    after_threatment: '',
  });
  const [actions] = useOutlet('actions');

  useEffect(() => {
    if (invoice) {
      setValues({
        reason: invoice.reason || '',
        after_threatment: invoice.after_threatment || 'default',
      });
    }
  }, [invoice]);

  const submit = useCallback(
    async (values) => {
      actions.setLoading(true);
      try {
        const {id} = invoice;
        const {reason, after_threatment} = values;
        if (uiState === UISTATE.void) {
          if (typeof reason === 'string' && reason.length > 6) {
            throw new ErrInvoice('只能填寫6個中文字');
          }
          await actions.voidInvoice({
            invoice: id,
            reason,
          });
        } else if (uiState === UISTATE.after_treatment) {
          await actions.editInvoiceAfterTreatmentState({
            invoice: id,
            after_threatment,
          });
        }
        Ant.message.success('更新成功。');
        onUpdate();
        onClose();
      } catch (ex) {
        if (ex?.error === 'void_error' && typeof ex.detail === 'string') {
          Ant.message.error(ex.detail);
        } else {
          errorHandler(ex);
        }
      }
      actions.setLoading(false);
    },
    [uiState, invoice],
  );

  return (
    <Ant.Modal
      visible={uiState}
      title={uiState}
      footer={
        <Row style={{justifyContent: 'flex-end'}}>
          <Button style={{marginRight: 10}} onClick={onClose}>
            取消
          </Button>
          <Button onClick={() => submit(values)}>確定</Button>
        </Row>
      }
      closeIcon={<Close size={20} />}
      onCancel={onClose}
      width="80%">
      <Block>
        <div>
          {uiState === UISTATE.void ? (
            <div className="row">
              <h4>原因</h4>
              <Ant.Input.TextArea
                value={values.reason}
                style={{marginRight: 10}}
                onChange={(e) => {
                  let reason = e.target.value;
                  setValues((prev) => ({...prev, reason}));
                }}
              />
              <Hint type="info">只能填寫6個中文字</Hint>
            </div>
          ) : uiState === UISTATE.after_treatment ? (
            <div className="row">
              <h4>處理狀態</h4>
              <Ant.Select
                value={values.after_threatment}
                onChange={(value) =>
                  setValues((prev) => ({...prev, after_threatment: value}))
                }>
                {Object.keys(INVOICE_AFTER_TREATMENT_STATE).map((key) => (
                  <Ant.Select.Option key={key} value={key}>
                    {INVOICE_AFTER_TREATMENT_STATE[key]}
                  </Ant.Select.Option>
                ))}
              </Ant.Select>
            </div>
          ) : null}
        </div>
      </Block>
    </Ant.Modal>
  );
}
